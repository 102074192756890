<template>
  <div>
          <v-carousel hide-delimiters show-arrows-on-hover cycle style="height:200px" continuous>
            <template v-for="(item, index) in lista">
              <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index" >
                <v-row class="flex-nowrap" style="height:200px">
                  <template v-for="(n,i) in columns">
                    <template v-if="(+index + i) < lista.length">
                      <v-col :key="i">
                        <v-sheet v-if="(+index + i) < lista.length" height="200px" class="sheetComHover"
                                 :style="'background: white;' + (lista[index + i].link !== '/' ? 'cursor: pointer' :'')"
                                 @click="IrParaUrl(lista[index + i].link)">
                          <v-row class="fill-height" align="center" justify="center">
                            <div class="display-3">
                              <v-img :src="lista[index + i].src"  class="sheetimagemComHover"></v-img>
                            </div>
                          </v-row>
                        </v-sheet>
                      </v-col>
                    </template>
                  </template>
                </v-row>
              </v-carousel-item>
            </template>
          </v-carousel>
  </div>
</template>

<script async>
/* eslint-disable */
  export default {
    name: 'VSlideGrupo',
    props: {
      lista: {default: Array}
    },
    components: {},
    data: () => ({
    }),
    methods: {
      IrParaUrl(path, target='_blank'){
        if (path !=='/') {
          var a = document.createElement('a')
          a.href = path
          a.target = target
          a.click()
        }

      },
    },
    created() {
    },
    mounted() {
    },
    computed: {
      columns() {
        if (this.$vuetify.breakpoint.xs) {
          return 2
        }
        if (this.$vuetify.breakpoint.xl) {
          return 6
        }

        if (this.$vuetify.breakpoint.sm) {
          return 4
        }

        if (this.$vuetify.breakpoint.lg) {
          return 6
        }

        if (this.$vuetify.breakpoint.md) {
          return 5
        }

        return 1
      }
    },
    watch: {}
  }
</script>

<style scoped>

  .sheetimagemComHover {
    width: 100px;
    opacity: 0.6!important;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: transform 0.2s linear;
    -moz-transition: transform 0.2s linear;
    -o-transition: transform 0.2s linear;
    transition: transform 0.2s linear !important;
    filter: grayscale(100%);

  }
  .sheetComHover:hover .sheetimagemComHover {
    opacity: 1!important;
    -webkit-transform: scale(1.3);
    transform: rotate(-15deg) scale(1.3) !important;
    filter: none;

  }

</style>
